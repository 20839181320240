<template>
    <div class="shopCart">
        <!-- <div style="margin-bottom:20px;" class="flex">
            <div class="coverImg flex-justify-between">
                <img v-if="shopCart.designPhotos" :src="imgUrl+shopCart.designPhotos[0]" alt="">
            </div>
            <div class="flex-1 flex-justify-between">
                <h3 v-if="shopCart.name" class="text-name">{{shopCart.name}}</h3>
                <p v-if="shopCart.saleByUser" class="text-type">设计师：{{shopCart.saleByUser.name}}</p>
                <p v-if="shopCart.saleByComp" class="text-company">{{shopCart.saleByComp.name}}</p>
            </div>
        </div> -->
        <!-- <div class="nav-header">
            <div class="flex">
                <div class="flex">合作工厂</div>
                <div class="flex-1">交期</div>
                <div class="flex-1">数量</div>
                <div class="flex-1">单价</div>
                <div class="flex-1" style="width:120px;">采购量</div>
                <div class="flex-1">小计</div>
            </div>
        </div>
        <div v-if="shopCart" class="shopcart-content">
            <div class="shopcart-item">
                <el-table v-if="stocks" :data="stocks" style="font-size: 14px;" ref="_table">
                    <el-table-column style="text-align: center"  width="150px">
                    </el-table-column>
                    <el-table-column prop="stock">
                        <template v-if="scope.row.stock" slot-scope="scope">    
                            <div class="range-item" v-for="(stock,idx) in scope.row.stock" :key="idx">
                                <div class="range">
                                    <div class="start">{{stock.range.start}}</div>
                                    <div class="line">—</div>
                                    <div class="end">{{stock.range.end}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stock" >
                        <template slot-scope="scope">
                            <div class="range-item" v-for="(stock,idx) in scope.row.stock" :key="idx">
                                <span>{{stock.price}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column class="shop-style">
                        <template slot-scope="scope">
                            <div>{{scope.row.allCount*scope.row.price}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pop-alert">采购量超出（未达到）该档数量要求，请上浮（下浮）档位</div> -->
        <div class="stocks">
            <ItemQuote v-if="stocks" :quote="stocks"></ItemQuote>
        </div>
        <div class="shopCart-bottom flex">
            <div class="flex-1">请输入需要订购的总量</div>
            <div>
                <el-input-number size="mini" v-model="totalNum" :min="0"></el-input-number>
            </div>
        </div>
        <div class="shop-cart flex">
            <div class="flex-1"></div>
            <div class="shop-cart-num flex">数量总计：<span>{{totalNum}}</span>件</div>
            <div class="shop-cart-price flex">总价：<span>￥{{totalPrice}}</span></div>
            <div @click="submit()" class="shop-cart-button flex" style="text-align: center">放入采购车</div>
        </div>
    </div>
</template>

<script>
    import { IMG_URL_PRE } from '@/config';
    import { getItem } from '@/service/mall';
    import { addToPurCar,countPurCar } from '@/service/sale';
    import ItemQuote from '@/components/factory/ItemQuote.vue';

    export default {
        components: {
            ItemQuote
        },
        model: {
            prop: 'shopCart',
            event: 'change',
        },
        props: ['shopCart'],
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                shopNum:1,
                stocks:null,
                price:10,
                fashionsId:null,
                compId:null,
                compGoodsId:null,
                totalNum:0,
            }
        },
        // watch: {
        //     shopCart: {
        //         id:{
        //             handler() {
        //                 this.refreshItems();
        //             },
        //         },
        //         deep: true
        //     },
        // },
        computed: {
            // totalNum() {
            //     var total = 0;
            //     this.newStocks.forEach(e => {
            //             total += e.allCount;
            //         });
            //     return total;
            // },
            totalPrice() {
                var total = 0;
                this.stocks.forEach((e,idx) => {
                    if (idx<=2 && this.totalNum>=e.start && this.totalNum<=e.end) {
                        total = this.totalNum*e.price;
                    }
                    if (idx>2 && this.totalNum>=e.start) {
                        total = this.totalNum*e.price;
                    }
                });
                return total;
            },
        },
        created() {
            console.log(this.shopCart);
            this.refreshItems();
        },
        mounted() {
            // this.refreshItems();
            // console.log(this.shopCart.colorIndex);
            // console.log(this.shopCart.shopCartIdx);
        },
        methods: {
            refreshItems() {
                if (this.shopCart && this.shopCart.companyItems && this.shopCart.companyItems.length>0) {
                    this.stocks = this.shopCart.companyItems[this.shopCart.shopCartIdx].prices;
                }
            },
            // 计数器
            handleChange(value) {
                console.log(value);
            },
            submit() {
                this.fashionsId = this.shopCart.fashions[this.shopCart.colorIndex].id;
                this.compId = this.shopCart.companyItems[this.shopCart.shopCartIdx].compId;
                this.compGoodsId = this.shopCart.companyItems[this.shopCart.shopCartIdx].id;
                // console.log(this.fashionsId);
                // console.log(this.compId);
                // console.log(this.compGoodsId);
                if (this.totalNum>0) {
                    addToPurCar(this.shopCart.id,this.totalNum,this.fashionsId,this.compId,this.compGoodsId).then(rst => {
                        console.log('addToPurCar',rst);
                        // this.$message.success("放入采物车成功");
                        // this.$store.commit('setShopNum',1);
                        // this.$emit('hide',);
                        this.getShopNum();
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else {
                    this.$message.success("请添加商品");
                }
            },
            getShopNum() {
                countPurCar().then(rst => {
                    console.log('countPurCar',rst);
                    this.$store.state.ShopNum = rst;
                    this.$message.success("放入采物车成功");
                    this.$emit('hide');
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    div.dialog>>> .el-table .cell {
        text-align: center;
    }
    div.dialog>>> .el-input-number--mini{
        width: 100px;
        overflow: hidden;
    }
    div.dialog>>> .el-input--mini .el-input__inner {
        width: 100px;
    }
    .coverImg {
        padding-right: 20px;
    }
    .coverImg img{
        width: 120px;
        height: 120px;
    }
    .text-name {
        text-align: left;
        font-size: 16px;
        font-weight:bold;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 17px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .text-type,.text-company,.text-mi {
        text-align: left;
        font-weight:500;
        font-size: 14px;
        color:rgba(128,128,128,1);
        margin-bottom: 10px;
    }
    .text-mi {
        color: #4D4D4D;
    }
    .text-company:last-child {
        margin-bottom: 0;
    }
    .shop-icon {
        text-align: center;
        height: 35px;
        padding: 6px;
        background: #E6E6E6;
        margin-bottom: 1px;
    }
    .shop-icon:last-child {
        margin-bottom: 0px;
    }
    .shop-icon img {
        width: 20px;
        height: 20px;
    }
    .nav-header {
        width: 100%;
        height: 60px;
        line-height: 60px;
        background: #F5F5F5;
        overflow-x: auto;
    }
    .nav-header>div {
        min-width: 550px;
    }
    .nav-header>div>div {
        text-align: center;
        padding:0 10px;
    }
    .nav-header>div>div:first-child {
        width: 150px;
        text-align: left;
    }
    /*----内容表格----*/
    .shopcart-content {
        width: 100%;
        background:rgba(245,245,245,1);
    }
    .shopcart-item {
        position: relative;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
    }
    .shopcart-item-name {
        padding: 0 10px;
        height: 45px;
        line-height: 45px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
    .pop-alert {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #fff;
        font-size: 12px;
        color: #F66F6A;
    }
    .stocks {
        background: #fff;
        padding: 20px;
    }
    .shopCart-bottom {
        padding: 16px;
        line-height: 28px;
        background: #fff;
        margin: 20px 0;
    }
    .shop-cart {
        width: 100%;
        height: 65px;
        line-height: 65px;
        background: #fff;
    }
    .shop-cart>div {
        font-size: 14px;
        color: #808080;
        margin-left: 40px;
    }
    .shop-cart div.shop-cart-num>span {
        font-size: 16px;
        color: #4D4D4D;
    }
    .shop-cart div.shop-cart-price>span {
        font-size: 18px;
        color: #D70000;
    }
    .shop-cart div.shop-cart-button {
        padding: 0 15px;
        background: #CC995A;
        color: #fff;
        cursor: pointer;
    }
</style>